import { AdvancedImage, lazyload, placeholder, responsive } from '@cloudinary/react';
import { dpr } from '@cloudinary/url-gen/actions/delivery';
import { Text, TextVariant } from '@naf/text';
import { breakpoints, spacing } from '@naf/theme';
import { nafColor } from '@nafcore/theme';
import React, { useState } from 'react';
import styled from 'styled-components';
import { HeroContentType } from '../../../../types/CategoryAppType';
import Timer from '../../components/SectionBlocks/blocks/CountdownBlock/Timer';
import { useCloudinary } from '../../hooks/useCloudinary';
import { useDevicePixelRatio } from '../../hooks/useDevicePixelRatio';

type HeroContentProps = {
  heroContent: HeroContentType;
};

const HeroContent = ({ heroContent }: HeroContentProps) => {
  const cld = useCloudinary();
  const devicePixelRatio = useDevicePixelRatio();
  const cldImage = heroContent.logoImage?.publicId
    ? cld.image(heroContent.logoImage?.publicId).delivery(dpr(devicePixelRatio)).quality('auto:best').format('auto')
    : undefined;
  return (
    <HeroWrapper>
      {cldImage ? (
        <ImageWrapper>
          <AdvancedImage
            style={{ maxHeight: spacing.space32 }}
            alt={heroContent.logoImage?.altText}
            cldImg={cldImage}
            plugins={[
              lazyload({ rootMargin: '10px 20px 10px 30px', threshold: 0.25 }),
              responsive({ steps: 200 }),
              placeholder({ mode: 'blur' }),
            ]}
          />
        </ImageWrapper>
      ) : null}
      <TitleText variant={TextVariant.Display}>{heroContent.pageTitle}</TitleText>
      {heroContent.countdown ? <Countdown countdown={heroContent.countdown} /> : null}
    </HeroWrapper>
  );
};

const Countdown = ({ countdown }: Required<Pick<HeroContentType, 'countdown'>>) => {
  const [isFinished, setIsFinished] = useState(false);

  return isFinished ? (
    <TitleText variant={TextVariant.Display}>{countdown.endText}</TitleText>
  ) : (
    <Timer targetTime={countdown.countdownTo} onFinished={() => setIsFinished(true)} />
  );
};

const TitleText = styled(Text)`
  color: ${nafColor.signature.white};
  margin: 0;

  &:not(:first-child) {
    margin-top: ${spacing.space24};
  }

  &:not(:last-child) {
    margin-bottom: ${spacing.space16};
  }

  :last-child {
    margin-top: 0;
  }

  @media (max-width: ${breakpoints.m}) {
    padding: 0 24px;
    text-align: center;
  }
`;

const HeroWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  margin: 0 auto ${spacing.space160} auto;

  color: ${nafColor.signature.white};

  h3,
  h6,
  ${TitleText} {
    text-shadow: 0 2px ${spacing.space12} rgba(0, 0, 0, 0.24);
  }
`;

const ImageWrapper = styled.div`
  padding-top: ${spacing.space160};
`;

export { HeroContent };
